import React, { useEffect, useState } from "react";
import "./locationfind.scss";

// Haversine formula to calculate distance in meters
const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371e3; // Earth radius in meters
  const toRadians = (degrees) => (degrees * Math.PI) / 180;

  const φ1 = toRadians(lat1);
  const φ2 = toRadians(lat2);
  const Δφ = toRadians(lat2 - lat1);
  const Δλ = toRadians(lon2 - lon1);

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // Distance in meters
};

const LocationTracker = () => {
  const hotelLocation = {
    lat: 12.975765, // Replace with hotel's latitude
    lon: 77.633988, // Replace with hotel's longitude
  };

  const [customerLocation, setCustomerLocation] = useState(null);
  const [alertVisible, setAlertVisible] = useState(false);
  const [mode, setMode] = useState("tracking"); // "tracking" or "inactive"

  const DISTANCE_THRESHOLD = 500; // meters threshold

  useEffect(() => {
    if (mode === "inactive") {
      setAlertVisible(false);
      return; // Stop location tracking if mode is inactive
    }

    // Watch customer's position when mode is "tracking"
    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCustomerLocation({ lat: latitude, lon: longitude });


        // Calculate distance
        const distance = calculateDistance(
          hotelLocation.lat,
          hotelLocation.lon,
          latitude,
          longitude
        );

        console.log(distance);
        // Show alert if customer moves away more than DISTANCE_THRESHOLD
        if (distance > DISTANCE_THRESHOLD) {
          setAlertVisible(true);
        } else {
          setAlertVisible(false);
        }
      },
      (error) => {
        console.error("Error watching position:", error);
      },
      { enableHighAccuracy: true, maximumAge: 10000, timeout: 5000 }
    );

    // Cleanup on component unmount
    return () => navigator.geolocation.clearWatch(watchId);
  }, [mode]); // Re-run the effect when mode changes

  // Function to toggle between modes
  const toggleTrackingMode = () => {
    setMode(mode === "tracking" ? "inactive" : "tracking");
  };

  return (
    <div id="Container">
      <h1>Hotel Location Tracker</h1>
      <p>
        Hotel Location: {hotelLocation.lat}, {hotelLocation.lon}
      </p>
      <p>
        Customer Location:{" "}
        {customerLocation
          ? `${customerLocation.lat}, ${customerLocation.lon}`
          : "Fetching..."}
      </p>

      {alertVisible && (
        <div style={{ color: "red", fontWeight: "bold" }}>
          Alert: Customer is more than {DISTANCE_THRESHOLD} meters away!
        </div>
      )}

      <button onClick={toggleTrackingMode}>
        {mode === "tracking" ? "Stop Tracking" : "Start Tracking"}
      </button>
    </div>
  );
};

export default LocationTracker;
